<template>
  <div class="d-flex align-items-center justify-content-center h-100">
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <div class="card-header text-center">
            <img src="/img/logo.svg" alt="Logo" class="brand-logo my-3"/>
            <h3 class="m-0">Conección POA</h3>
            <small>Sistema de Administración General</small>
          </div>
          <div class="text-center"><button class="btn btn-secondary float-right" @click="goToEvents()">Login Usuario</button></div>
          <h4>Registrar Usuario</h4>
        </div>
        <form @submit.prevent="registerUser">
          <div class="card-body">
            <FormText
              label="Nombre"
              v-model="form.first_name"
            />
            <FormText
              label="Apellido"
              v-model="form.last_name"
            />
            <FormText
              label="Email"
              v-model="form.email"
            />
            <FormText
              label="Password"
              password
              v-model="form.password"
            />
            <FormText
              label="Repetir Password"
              password
              v-model="form.password_confirmation"
            />

          </div>
          <div class="card-footer">
            <button class="btn btn-primary btn-block" type="submit">
              Registrase
            </button>
          </div>
          <div class="text-center mt-3 mb-5">
            <small>
              by <a href="http://trememote.com.ar" target="_blank">&nbsp;Trememote</a>
            </small>
          </div>
        </form>
      </div>

    </div>
  </div>
</template>

<script>
import FormText from "@/components/Form/FormText.vue";

export default {
  components: {
    FormText,
  },
  data: () => ({
    form: {
      first_name: null,
      last_name: null,
      email: null,
      password: null,
      password_confirmation: null,
    },
  }),
  beforeMount() {
    this.$store.dispatch("setCredentials");
    const isLoggedIn = this.$store.getters.isLoggedIn;

    if (isLoggedIn) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    registerUser() {
      this.$store
          .dispatch("register", this.form).then(() => {
          this.$store.dispatch('logout')
        });
    },
    goToEvents: function () {
        this.$router.push('/login')
    }
  },
};
</script>
